/* eslint-disable react/prop-types */
import React from 'react';
import { silentAuth } from './src/utils/auth';

class SessionCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession);
  }

  handleCheckSession = () => {
    this.setState({ loading: false });
  };

  render() {
    const { children } = this.props;
    const { loading } = this.state;

    return loading === false && <>{children}</>;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  return <SessionCheck>{element}</SessionCheck>;
};
